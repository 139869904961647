<template>
    <v-app-bar  color="white" elevation="0">
      <div class="d-flex justify-space-between align-center tw-w-full px-0 px-sm-16">
        <div>
          <router-link to="/">
            <v-img
                :width="60"
                :src="brandLogo"
                transition="scale-transition"
            />
          </router-link>
        </div>
        <div>
          <h4 class="design__title">{{$route.query.title? $route.query.title :'Untitled Design'}}</h4>
        </div>
        <div>
          <v-btn
            v-if="$route.name==='Build'"
            :loading="loading"
              elevation="0"
              rounded
              @click="openDrawer"
              style="background: #FF7A01;font-family: 'DM Sans', sans-serif;
          font-weight: 700;font-size: 13px; color: white">Next <v-icon right>mdi-arrow-right</v-icon> </v-btn>
          <v-btn
            v-else
            :loading="loading"
            elevation="0"
            rounded
            @click="$emit('click')"
            style="background: #FF7A01;font-family: 'DM Sans', sans-serif;
          font-weight: 700;font-size: 13px; color: white">Next <v-icon right>mdi-arrow-right</v-icon> </v-btn>
        </div>
      </div>
    </v-app-bar>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import brandLogo from "@/assets/logo.svg"
import { toPng, toSvg } from "html-to-image";

export default {
  name: "BuildHeader",
  data: () => ({
    brandLogo,
    loading:false
  }),
  props:{
    titleHeader : String
  },
  computed:{
    ...mapState('buildMerch', ['designTitle', 'canvas', 'canvasImageURL', 'productName','canvasFrontJson','canvasBackJson']),
    ...mapGetters('buildMerch', ['selectedShirt','selectedBackShirt']),
  },
  // created() {
  //   if(this.designTitle===''){
  //     this.UPDATE_STATE_PROPERTY({prop: 'designTitle', value: JSON.parse(localStorage.getItem('allDesignProps')).designTitle})
  //   }
  // },
  methods: {
    ...mapMutations('buildMerch', ['UPDATE_STATE_PROPERTY']),
    openDrawer () {
      this.$emit('on-next')
      this.convertCanvasToSVG()
    },
    async convertCanvasToSVG () {
      this.loading = true
      const SVGFormat = await toPng(document.querySelector('.front'))
      this.UPDATE_STATE_PROPERTY({prop: 'canvasImageURL', value: SVGFormat})
      // this.UPDATE_STATE_PROPERTY({prop: 'exportCanvasDrawer', value: true})
      this.routeToCartToCart()
      this.loading = false

    },
    convertCanvas (format) {
      return this.canvas.toDataURL({
        format,
      })
    },
    routeToCartToCart() {
      let front = {
        front: this.canvasFrontJson,
        selectedShirt : this.selectedShirt,
        title:this.designTitle
      }
      let back
      if(Object.keys(this.canvasBackJson).length){
        back = {
          back: this.canvasBackJson,
          selectedShirt : this.selectedBackShirt,
          title:this.designTitle
        }
      }

      localStorage.setItem('frontBuildJson',JSON.stringify(front))
      localStorage.setItem('backBuildJson',JSON.stringify(back))

      // this.$store.commit('buildMerch/SET_ALL_STATE')
      this.$router.push({ name: 'AddToCart', query: { productName: this.productName, title: this.designTitle } })
    }
  }
}
</script>

<style scoped>
.appbar{
  display: flex;
  height: 5.125rem;
  width: 100%;
  padding: 2% 5%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
  position: absolute;

}
.label{
  display: flex;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  width: 32%;
  align-items: center;
  justify-content: center;
}

.button-group{
  display: flex;
  width: 32%;
  align-items: center;
  justify-content: flex-end;
}

h4.design__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 23.44px;
}

</style>
